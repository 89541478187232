import { FetchError } from 'ofetch'

import { setupLayouts } from 'virtual:generated-layouts'
import type { App } from 'vue'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router/auto'
import { createRouter, createWebHistory } from 'vue-router/auto'
import { useAuthStore } from '@/stores/auth'
import { useAuthStore as useAdminAuthStore } from '@/stores/admin'
import { paymentHasAccount, twoFa } from '@/plugins/2.router/utils'

function recursiveLayouts(
  route: RouteRecordRaw,
): RouteRecordRaw {
  if (route.children) {
    for (let i = 0; i < route.children.length; i++) {
      route.children[i] = recursiveLayouts(
        route.children[i],
      )
    }

    return route
  }

  return setupLayouts([route])[0]
}

const router = createRouter({
  history: createWebHistory('/'),
  scrollBehavior(to) {
    if (to.hash)
      return { el: to.hash, behavior: 'smooth', top: 60 }

    return { top: 0 }
  },
  extendRoutes: pages => [
    ...[...pages].map(route => recursiveLayouts(route)),
  ],
})

const middlewares: any = {
  payment: paymentHasAccount,
  twoFa,
}

const checkFor2FA = async (store: ReturnType<typeof useAuthStore> | ReturnType<typeof useAdminAuthStore>) => {
  try {
    const res = await store.getStatus()

    console.log(res)

    return true
  }
  catch (e) {
    if (e instanceof FetchError && e.data.error === 'two_factor')
      return false
  }
}

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
  if (to.meta.auth) {
    const guard = to.meta.guard ?? 'user'
    let isLoggedIn: boolean = false
    let redirectTo: string | object = ''
    if (guard === 'user') {
      const authStore = useAuthStore()
      if (!authStore.isAuth) {
        isLoggedIn = false
        redirectTo = 'login'
      }
      else {
        if (await checkFor2FA(authStore))
          isLoggedIn = true
        else
          redirectTo = 'check2fa'
      }
    }
    else if (guard === 'admin') {
      const authStore = useAdminAuthStore()

      if (await checkFor2FA(authStore))
        isLoggedIn = true

      else
        redirectTo = '/admin/check_2fa'
    }

    if (!isLoggedIn) {
      return next(redirectTo)
    }
    else if (to.meta.verified) {
      const { user } = useAuthStore()
      if (!user?.email_verified_at)
        return next('/email/verify')
    }
  }

  if (to.meta.middleware) {
    if (Array.isArray(to.meta.middleware)) {
      let result = null
      to.meta.middleware.forEach((middleware: string) => {
        const middlewareReturn = middlewares[middleware](
          to,
          from,
          next,
        )

        console.log(middlewareReturn)

        if (
          middlewareReturn instanceof String || middlewareReturn instanceof Object
        )
          result = middlewareReturn
      })
      if (result)
        return next(result)
    }
    else if (to.meta.middleware instanceof String) {
      const middlewareReturn = middlewares[
        to.meta.middleware as string
      ](to, from, next)

      if (middlewareReturn instanceof String)
        return next(middlewareReturn as string)
    }
  }
  next()
})

export { router }

export default function (app: App) {
  app.use(router)
}
