<script setup lang="ts">
import { useTheme } from 'vuetify'
import Footer from '@/views/front-pages/front-page-footer.vue'
import Navbar from '@/views/front-pages/front-page-navbar.vue'
import Banner from '@/views/front-pages/landing-page/banner.vue'
import ContactUs from '@/views/front-pages/landing-page/contact-us.vue'
import FaqSection from '@/views/front-pages/landing-page/faq-section.vue'
import Features from '@/views/front-pages/landing-page/features.vue'
import HeroSection from '@/views/front-pages/landing-page/hero-section.vue'
import PricingPlans from '@/views/front-pages/landing-page/pricing-plans.vue'
import ProductStats from '@/views/front-pages/landing-page/product-stats.vue'
import AffiliatesDark from '@images/front-pages/landing-page/affiliates-dark.jpeg'
import AffiliatesLight from '@images/front-pages/landing-page/affiliate-light.jpeg'

definePage({
  meta: {
    layout: 'blank',
  },
})

const theme = useTheme()
const activeSectionId = ref()

const refHome = ref()
const refFeatures = ref()
const refTeam = ref()
const refContact = ref()
const refFaq = ref()
const { locale } = useI18n()

useIntersectionObserver(
  [refHome, refFeatures, refTeam, refContact, refFaq],
  ([{ isIntersecting, target }]) => {
    if (isIntersecting)
      activeSectionId.value = target.id
  },
  {
    threshold: 0.25,
  },
)
</script>

<template>
  <div class="landing-page-wrapper">
    <Navbar :active-id="activeSectionId" />

    <!-- 👉 Hero Section  -->
    <HeroSection ref="refHome" />

    <!-- 👉 Useful features  -->
    <div :style="{ 'background-color': 'rgb(var(--v-theme-surface))' }">
      <Features ref="refFeatures" />
    </div>

    <!-- 👉 Customer Review -->
    <!--
      <div :style="{ 'background-color': 'rgb(var(&#45;&#45;v-theme-surface))' }">
      <CustomersReview />
      </div>
    -->

    <!-- 👉 Our Team -->
    <div :style="{ 'background-color': 'rgb(var(--v-theme-surface))' }">
      <VContainer>
        <div class="d-flex justify-center align-center mb-8">
          <iframe
            width="726"
            height="410"
            :src="locale === 'en' ? 'https://www.youtube.com/embed/App42BDG_Ww?si=Ji1dFm_Bcn4v6_Xc' : 'https://www.youtube.com/embed/f9iyPxl6z4s?si=EHCJYAJbZQ9zJw_3'"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          />
        </div>
      </VContainer>
      <!--      <OurTeam ref="refTeam" /> -->
    </div>
    <!-- 👉 Pricing Plans -->
    <div :style="{ 'background-color': 'rgb(var(--v-theme-surface))' }">
      <PricingPlans />
    </div>

    <!-- 👉 Product stats -->
    <ProductStats />

    <!-- 👉 FAQ Section -->
    <div :style="{ 'background-color': 'rgb(var(--v-theme-surface))' }">
      <FaqSection ref="refFaq" />
    </div>

    <!-- 👉 Banner  -->
    <Banner />

    <div class="landing-cta bg-surface">
      <VContainer>
        <div class="d-flex align-center justify-center flex-wrap gap-6 gap-x-10 position-relative">
          <div class="d-flex justify-center align-center">
            <div class="text-center">
              <h2 class="text-h2 text-primary font-weight-bold">
                {{ $t('pages.home.affiliates.title') }}
              </h2>
              <br>
              <h5
                class="text-h5 font-weight-medium mb-8"
                v-html="$t('pages.home.affiliates.subtitle')"
              />
              <div class="d-flex flex-column justify-center align-center affiliate-action">
                <VImg
                  inline
                  :src="theme.current.value.dark ? AffiliatesDark : AffiliatesLight"
                  class="mt-4 mb-4 w-100"
                />
                <VBtn
                  color="primary"
                  :to="{ name: 'login' }"
                  height="48"
                >
                  {{ $t('pages.home.affiliates.button') }}
                </VBtn>
              </div>
            </div>
          </div>
        </div>
      </VContainer>
    </div>

    <!-- 👉 Contact Us  -->
    <ContactUs ref="refContact" />

    <!-- 👉 Footer -->
    <Footer />
  </div>
</template>

<style lang="scss">
@media (max-width: 960px) and (min-width: 600px) {
  .landing-page-wrapper {
    .v-container {
      padding-inline: 2rem !important;
    }
  }
}
</style>

<style scoped lang="scss">
.affiliate-action {
  padding-bottom: 2rem;
  @media (min-width: 1024px){
    .v-responsive {
      max-width: 700px;
    }
  }
}
</style>
