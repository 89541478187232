<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { router } from '@/plugins/2.router'
import { ValidationError } from '@api/errors'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import { useAuthStore } from '@/stores/auth'
import authV2ForgotPasswordIllustrationDark from '@images/pages/auth-v2-verify-email-illustration-dark.png'
import authV2ForgotPasswordIllustrationLight from '@images/pages/auth-v2-verify-email-illustration-light.png'
import authV2MaskDark from '@images/pages/misc-mask-dark.png'
import authV2MaskLight from '@images/pages/misc-mask-light.png'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'

definePage({
  name: 'email-verify',
  meta: {
    layout: 'blank',
    auth: true,
  },
})

const authThemeImg = useGenerateImageVariant(
  authV2ForgotPasswordIllustrationLight,
  authV2ForgotPasswordIllustrationDark,
)

const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)

const { user, ...authStore } = useAuthStore()

const state = reactive({
  code: '',
})

const rules = {
  code: [required],
}

const $externalResults = ref({})

const v$ = useVuelidate(rules, state, { $externalResults })

const loading = ref(false)
const overlayLoading = ref(false)
const error = ref('')
const message = ref('')

const submit = async () => {
  try {
    loading.value = true
    error.value = ''
    message.value = ''

    const res = await authStore.verifyEmail(state)

    loading.value = false
    message.value = res.message
    await nextTick(() => {
      router.replace({ name: 'user' })
    })
  }
  catch (err) {
    if (err instanceof ValidationError) {
      error.value = err.message
      $externalResults.value = err.errors ?? {}
    }
    loading.value = false
  }
}

const resend = async () => {
  overlayLoading.value = true

  const res = await authStore.resendVerificationEmail()

  overlayLoading.value = false
  message.value = res.message
}
</script>

<template>
  <VOverlay
    :model-value="overlayLoading"
    class="align-center justify-center"
  >
    <VProgressCircular
      color="primary"
      indeterminate
      size="64"
    />
  </VOverlay>
  <VRow
    class="auth-wrapper bg-surface"
    no-gutters
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <div class="d-flex align-center justify-center w-100 h-100">
          <VImg
            max-width="418"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <VImg
          class="auth-footer-mask"
          :src="authThemeMask"
        />
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-4"
      >
        <VCardText>
          <VNodeRenderer
            :nodes="themeConfig.app.logo"
            class="mb-6"
          />

          <h4 class="text-h4 mb-1">
            Verify your email ✉️
          </h4>
          <p>
            Account activation link sent to your email address: {{ user!.email }} Please follow the link inside to
            continue.
            <br><br>
            <span class="font-weight-bold">Or</span>
            <br><br>
            you can enter the code you received in the email in the input below and click on <span class="font-weight-bold">submit</span>
          </p>
          <form @submit.prevent="submit">
            <AppTextField
              v-model="v$.code.$model"
              :field="v$.code"
              :disabled="loading"
              class="mb-2"
              label="Verification code"
            />
            <VBtn
              block
              type="submit"
              class="mt-5 mb-6"
              :loading="loading"
            >
              Submit
            </VBtn>
          </form>

          <div class="d-flex align-center justify-center">
            <span class="me-1">Didn't get the mail? </span><a
              class="cursor-pointer"
              @click.prevent="resend"
            >Resend</a>
          </div>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth";
</style>
