<script setup lang="ts">
import type { Bot, Exchange } from '@api/models/exchange'
import type { User } from '@api/models/user'
import { Account } from '@api/models/account'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const users = ref<User[]>([])

const settings = ref({
  enabled: false,
  user_id: null,
  total_balance: 0,
  profit_and_loss: 0,
  today_bot_profit: 0,
  total_bot_profit: 0,
})
const exchanges = ref<Exchange[]>([])

const bots = ref<Bot[]>([])
const accounts = ref<Account[]>([])
const exchangeAccounts = ref<Record<number, Account>>({})


interface ExchangeValue {
  exchange_id: number
  total_balance: number
  profit_and_loss: number
  today_bot_profit: number
  total_bot_profit: number
  has_balance: boolean
  usdt_portfolio: boolean
  show_trades: boolean
  extend_subscription: boolean
	account_id: number
	bot_id: number
}
const loading = ref(false)
type ExchangeValueObj = Record<number, ExchangeValue>;
const exchangeValues = ref<ExchangeValueObj>(<ExchangeValueObj>{})

const onAccountUpdated = (exchange: number, account: Account) => {
	exchangeAccounts.value[exchange] = account
	exchangeValues.value[exchange].account_id = account?.account_id ?? null
	console.log(exchangeValues.value[exchange])
}
const getExchanges = async () => {
  const _exchanges = await $api('/admin/exchanges?nopaginate&active')

  const values: any = {}
  for (const exchange of _exchanges) {
    values[exchange.id] = {
      exchange_id: exchange.id,
      total_balance: 0,
      profit_and_loss: 0,
      today_bot_profit: 0,
      total_bot_profit: 0,
      has_balance: 1,
      usdt_portfolio: 0,
      show_trades: 1,
      extend_subscription: 0,
      account_id: null,
      bot_id: null,
    }
  }

  exchangeValues.value = values
  exchanges.value = _exchanges

  const testValues = await $api('/admin/test_values')
  for (const item of testValues)
    exchangeValues.value[item.exchange_id] = item
}

const getUsers = async () => {
  users.value = await $api('admin/users?nopaginate')
}

const getBots = async () => {
	bots.value = await $api('/admin/bots?nopaginate')
}

const getAccounts = async () => {
	accounts.value = await $api('/admin/accounts')
}

const toggleDemoMode = async () => {
  loading.value = true
  await $api('/admin/demo/settings', {
    method: 'POST',
    body: settings.value,
  })
  loading.value = false
}

const getSettings = async () => {
  loading.value = true

  settings.value = await $api('/admin/demo/settings')

  loading.value = false
}

const onSubmit = async () => {
	try {
		loading.value = true
		await $api('/admin/demo/settings', {
			method: 'POST',
			body: settings.value,
		})

		console.log(Object.values(exchangeValues.value))
		await $api('/admin/test_values', {
			method: 'POST',
			body: Object.values(exchangeValues.value),
		})
	} catch (e){

	} finally {
		loading.value = false
	}
}

getUsers()
getExchanges()
getSettings()
getAccounts()
getBots()
</script>

<template>

  <form @submit.prevent="onSubmit">

  <VCard
    class="mt-3"
  >
    <VOverlay contained :model-value="loading" persistent class="d-flex justify-center align-center">
      <VProgressCircular indeterminate size="x-large"/>
    </VOverlay>
    <VCardText>
      <VRow>
        <VCol>
          <VSwitch
            v-model="settings.enabled"
            label="Enable Demo Mode"
            @input="toggleDemoMode"
          />
        </VCol>
      </VRow>
      <VRow class="align-end">
        <VCol cols="4">
          <VSelect
            v-model="settings.user_id"
            :items="users"
            item-title="name"
            item-value="id"
            label="Test user"
          />
        </VCol>
        <VCol>
          <VRow>
            <VCol>
              <VLabel class="text-center justify-center w-100 mb-2">
                Total Balance
              </VLabel>
              <AppTextField v-model="settings.total_balance" />
            </VCol>
            <VCol>
              <VLabel class="text-center justify-center w-100 mb-2">
                Profit and Loss
              </VLabel>
              <VTextField v-model="settings.profit_and_loss" />
            </VCol>
            <VCol>
              <VLabel class="text-center justify-center w-100 mb-2">
                Today's Bot profit
              </VLabel>
              <VTextField v-model="settings.today_bot_profit" />
            </VCol>
            <VCol>
              <VLabel class="text-center justify-center w-100 mb-2">
                Total Bot Profit
              </VLabel>
              <VTextField v-model="settings.total_bot_profit" />
            </VCol>
          </VRow>

        </VCol>
      </VRow>
      <VRow v-for="exchange in exchanges"
            :key="exchange.id">
      <VCol>
        <VCard color="secondary">
          <VCardText>
            <VRow
            >
              <VCol
                cols="4"
                class="d-flex flex-column justify-center"
              >
                <VCard flat class="h-100">
                  <VCardTitle>{{ exchange.name }}</VCardTitle>
                  <VCardText>
                    <VList density="compact">
                      <VListItem>
                        <VSwitch
                          v-model="exchangeValues[exchange.id].has_balance"
                          label="Has Account Balance"
                          :false-value="0"
                          :true-value="1"
                        />
                      </VListItem>
                      <VListItem>
                        <VSwitch
                          v-model="exchangeValues[exchange.id].usdt_portfolio"
                          label="Portfolio 100% USDT"
                          :false-value="0"
                          :true-value="1"
                        />
                      </VListItem>
                      <VListItem>
                        <VSwitch
                          v-model="exchangeValues[exchange.id].show_trades"
                          label="Show Trades"
                          :false-value="0"
                          :true-value="1"
                        />
                      </VListItem>
                      <VListItem>
                        <VSwitch
                          v-model="exchangeValues[exchange.id].extend_subscription"
                          label="Show Extend Subscription"
                          :false-value="0"
                          :true-value="1"
                        />
                      </VListItem>
                    </VList>
                  </VCardText>
                </VCard>
              </VCol>
              <VCol class="d-flex flex-column justify-center">
                <VCard flat>
                  <VCardText>
                    <VRow>
                      <VCol class="d-flex gap-1">
                        <AppPercentageField
                          colro="primary"
                          v-model="exchangeValues[exchange.id].total_balance"
                          :whole="settings.total_balance"
                        />
                      </VCol>
                      <VCol class="d-flex gap-1">
                        <AppPercentageField
                          v-model="exchangeValues[exchange.id].profit_and_loss"
                          :whole="settings.profit_and_loss"
                        />
                      </VCol>
                      <VCol class="d-flex gap-1">
                        <AppPercentageField
                          v-model="exchangeValues[exchange.id].today_bot_profit"
                          :whole="settings.today_bot_profit"
                        />
                      </VCol>
                      <VCol class="d-flex gap-1">
                        <AppPercentageField
                          v-model="exchangeValues[exchange.id].total_bot_profit"
                          :whole="settings.total_bot_profit"
                        />
                      </VCol>
                    </VRow>
                    <VRow>
                      <VCol>
						  <AppAutocomplete clearable label="Account" :model-value="exchangeValues[exchange.id].account_id" @update:model-value="(val) => onAccountUpdated(exchange.id, val)"  :items="accounts" item-title="name" item-value="account_id" return-object/>
					  </VCol>
                      <VCol>
						  <AppAutocomplete clearable label="Bot" v-model="exchangeValues[exchange.id].bot_id" :items="exchangeAccounts[exchange.id] ? bots.filter(bot => bot.account_id === exchangeAccounts[exchange.id].id) : []" item-title="name" item-value="bot_id"/>
					  </VCol>
                    </VRow>
                  </VCardText>
                </VCard>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
      </VRow>
    </VCardText>
    <VCardActions>
      <VSpacer />
      <VBtn type="submit">
        Update
      </VBtn>
    </VCardActions>
  </VCard>
  </form>
</template>

<style scoped lang="scss">

</style>
